import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const byteFormatter = (bytes, decimals) => {
  if (!bytes || isNaN(bytes)) return '0';
  const k = 1024;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))}${sizes[i]}`
  return value.endsWith('b') ? value : '0';
};

function BandwidthUsage(props) {
  const { fqdn, direction, period } = props;
  const [observation, setObservation] = useState({ active: 0, max: 100, loading: true });
  useEffect(() => {
    const metric = (direction === 'in') ? 'node_network_receive_bytes_total' : 'node_network_transmit_bytes_total';
    const uri = `https://pulse.pelagos.systems/api/v1/query?query=${encodeURI(`increase(${metric}{instance="${fqdn}:443",device!="lo"}[${period}])`)}`;
    fetch(uri)
      .then((r) => r.json())
      .then(({ data: { result } }) => {
        const bytes = Math.max(...result.map(({ value: [_, b] }) => parseFloat(b)));
        setObservation({ bytes, loading: false, uri });
      })
      .catch((error) => setObservation({ loading: false , uri, error}));
  }, [fqdn, direction, period]);
  return (
    (!observation.loading)
      ? (!!observation.bytes)
          ? byteFormatter(observation.bytes, 0)
          : null
      : (
          <Spinner style={{...props.style}} animation="grow" size="sm" className="text-secondary">
            <span className="visually-hidden">{direction} bandwidth usage lookup in progress</span>
          </Spinner>
        )
  );
}

export default BandwidthUsage;
