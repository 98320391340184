import { useEffect, useState } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';
import getTcpConnections from './getTcpConnections';
import getWsConnections from './getWsConnections';

const threshold = {
  danger: 0.85,
  warning: 0.66,
};

function NodeConnections(props) {
  const { fqdn, protocol } = props;
  const [connections, setConnections] = useState({ active: 0, max: 100, loading: true });
  useEffect(() => {
    (
      (protocol === 'ws')
        ? getWsConnections(fqdn)
        : getTcpConnections(fqdn)
    ).then((observed) => setConnections({ ...observed, loading: false }));
  }, [fqdn, protocol]);
  return (
    (!connections.loading)
      ? (
          <ProgressBar
            striped
            now={((connections.active / connections.max) * 100)}
            title={`connections: ${connections.active} active / ${connections.max} max`}
            variant={
              ((connections.active / connections.max) > threshold.danger)
                ? 'danger'
                : ((connections.active / connections.max) > threshold.warning)
                  ? 'warning'
                  : 'success'
            }
          />
        )
      : (
          <Spinner style={{...props.style}} animation="grow" size="sm" className="text-secondary">
            <span className="visually-hidden">active connection count lookup in progress</span>
          </Spinner>
        )
  );
}

export default NodeConnections;
