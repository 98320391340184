
// todo, determine a way of understanding when a node has maxed out it's tcp cap[acity
const max = 32768; 

const getActive = async (fqdn) => {
  let active;
  try {
    const response = await fetch(`https://pulse.pelagos.systems/api/v1/query?query=node_netstat_Tcp_CurrEstab{instance%3D%22${fqdn}:443%22}`);
    const json = await response.json();
    active = json.data.result[0].value[1];
  } catch(exception) {
    active = 0;
  }
  return parseInt(active);
};

const getTcpConnections = async (fqdn) => {
  const active = await getActive(fqdn);
  return { active, max };
};

export default getTcpConnections;
